import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Close from "../../../../../assets/images/svg/icons/Close.svg"
import FreeTime from "../../../../../assets/images/svg/icons/FreeTime.svg"
import HPHT from "../../../../../assets/images/svg/icons/HPHT.svg"
import HardDate from "../../../../../assets/images/svg/icons/HardDate.svg"
import Info from "../../../../../assets/images/svg/icons/Info.svg"
import RigMove from "../../../../../assets/images/svg/icons/RigMove.svg"
import Sequence from "../../../../../assets/images/svg/icons/Sequence.svg"
import Locked from "../../../../../assets/images/svg/icons/locked.svg"
import Partner from "../../../../../assets/images/svg/icons/partner.svg"
import { getReadableRigType } from "../../../../lib/RigUtils"
import { CONFIRM_SCHEDULER_SHARE, CONFIRM_SET_AS_MASTER } from "../../../../panelConsts"
import { ActionCreators } from "../../../../redux/actions"
import { WELL_STATUS } from "../../../../redux/types"
import ApiServices from "../../../../services/ApiService"
import WellStatusName from "../../../common/WellStatusName"
import IconContainer from "../../../icons/IconContainer"
import RigTypeIcon from "../../../icons/RigTypeIcon"
import OverridePValuesPanel from "../OverridePValuesPanel"

const statusArr = Array.from(WELL_STATUS).map((val) => ({
  name: val[1].name,
  value: val[0],
  color: val[1].color,
}))

class CalendarLegend extends Component {
  constructor() {
    super()

    this.state = {
      explanationsActive: false,
      overridePanelActive: false,
      roleListActive: false,
    }
  }

  export = () => {
    const token = ApiServices.getCookie("jwt")
    const apiUrl = ApiServices.getApiUrl()
    window.location.href = `${apiUrl}v1/export/exportScheduleToExcel/Current Master.xlsx?token=${token}`
  }

  setAsMaster = () => {
    const { openPanelWithType } = this.props
    openPanelWithType(CONFIRM_SET_AS_MASTER)
  }

  shareSchedule = () => {
    const { openPanelWithType } = this.props
    openPanelWithType(CONFIRM_SCHEDULER_SHARE)
  }

  render() {
    const {
      schedulerData: { overridenPValueWells, schedulerData },
      currentUser,
      accessLevel,
      domain: {
        data: { activeRigTypes, additionalLegendInfo },
      },
    } = this.props

    const canImitateRole =
      accessLevel > 5 || (currentUser.original_groups && currentUser.original_groups.includes("95b54770-8ba8-40c8-8a6e-72951926791a"))

    const isAMaster = schedulerData && schedulerData.data ? schedulerData.data.readonly : null
    const canExport = schedulerData && schedulerData.data && schedulerData.data.is_master && schedulerData.data.master_to_date === null

    return (
      <div className="CalendarLegend">
        <div className="CalendarLegend__Wrapper">
          <div className="CalendarLegend__ButtonGroup">
            {accessLevel > 5 && (
              <button
                onClick={() => this.setState({ overridePanelActive: !this.state.overridePanelActive })}
                className={`CalendarLegend_Elm CalendarLegend__Button`}
              >
                Override P-values
                {overridenPValueWells && Object.keys(overridenPValueWells).length > 0 && (
                  <span className="CalendarLegend__NumIndicator">{Object.keys(overridenPValueWells).length}</span>
                )}
              </button>
            )}
          </div>
          <div className="CalendarLegend__ButtonGroup">
            <button
              onClick={canExport ? this.export : () => {}}
              className={`CalendarLegend_Elm CalendarLegend__Button${!canExport ? " CalendarLegend__Button--disabled" : ""}`}
            >
              Export to Excel
              {!canExport && <span>You can only export current master</span>}
            </button>
            {canImitateRole ? (
              <button className="CalendarLegend_Elm CalendarLegend__Button" onClick={() => this.shareSchedule()}>
                Share
              </button>
            ) : null}
            {canImitateRole && !isAMaster ? (
              <button onClick={this.setAsMaster} className="CalendarLegend_Elm CalendarLegend__Button">
                Set as master
              </button>
            ) : null}
          </div>
          <div className="CalendarLegend__Explanations">
            <button
              onClick={() =>
                this.setState({
                  explanationsActive: !this.state.explanationsActive,
                })
              }
              className={`CalendarLegend_Elm CalendarLegend__ExplanationsButton ${this.state.explanationsActive ? " active" : ""}`}
            >
              {this.state.explanationsActive ? IconContainer(Close, 13, 13) : IconContainer(Info, 18, 18)}
            </button>
          </div>
          <div className={`CalendarLegend__OverridePValuesPanel ${this.state.overridePanelActive ? " active" : ""}`}>
            <OverridePValuesPanel onClose={() => this.setState({ overridePanelActive: false })} />
          </div>
          <div className={`CalendarLegend__ExplanationsPanel ExplanationsPanel${this.state.explanationsActive ? " active" : ""}`}>
            <div className="ExplanationsPanel__Part WellStatus">
              <h3 className="ExplanationsPanel__Heading">Well status</h3>
              <ul className="ExplanationsPanel__List">
                {statusArr.map((val) => (
                  <li key={`ExplanationsPanel-WellStatus-${val.name}`} className="ExplanationsPanel__ListItem">
                    <span style={{ backgroundColor: val.color }} className="WellStatus__Color"></span>
                    <span className="ExplanationsPanel__ListItemValue">
                      <WellStatusName statusKey={val.value} />
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="ExplanationsPanel__Part Icons">
              <h3 className="ExplanationsPanel__Heading">Well info</h3>
              <ul className="ExplanationsPanel__List">
                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon">{IconContainer(HPHT, 14, 14)}</span>
                  <span className="ExplanationsPanel__ListItemValue">HPHT</span>
                </li>
                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon">{IconContainer(Locked, 14, 14)}</span>
                  <span className="ExplanationsPanel__ListItemValue">Bound by contract</span>
                </li>
                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon Icons__Icon--large">{IconContainer(RigMove, 27, 19)}</span>
                  <span className="ExplanationsPanel__ListItemValue">Rig move</span>
                </li>
                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon Icons__Icon--large">{IconContainer(Sequence, 27, 19)}</span>
                  <span className="ExplanationsPanel__ListItemValue">Sequential wells</span>
                </li>
                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon Icons__Icon--large">{IconContainer(HardDate, 27, 19)}</span>
                  <span className="ExplanationsPanel__ListItemValue">Underline = Hard date</span>
                </li>
              </ul>
            </div>
            <div className="ExplanationsPanel__Part Icons">
              <h3 className="ExplanationsPanel__Heading">Rig types</h3>
              <ul className="ExplanationsPanel__List">
                {activeRigTypes.map((e, k) => {
                  return (
                    <li key={`rigtype-${k}`} className="ExplanationsPanel__ListItem">
                      <span className="Icons__Icon">
                        <RigTypeIcon rigtype={e} />
                      </span>
                      <span className="ExplanationsPanel__ListItemValue rig">{getReadableRigType(e)}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="ExplanationsPanel__Part Icons">
              <h3 className="ExplanationsPanel__Heading">Rig info</h3>
              <ul className="ExplanationsPanel__List">
                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon">{IconContainer(Partner, 16, 16)}</span>
                  <span className="ExplanationsPanel__ListItemValue">Partner rig</span>
                </li>
                <li className="ExplanationsPanel__ListItem">
                  <span
                    style={{
                      height: "30px",
                      width: "46px",
                      left: "-10px",
                      position: "relative",
                      marginRight: "-13px",
                    }}
                    className="Icons__Icon Icons__Icon--large"
                  >
                    {IconContainer(FreeTime, 46, 30)}
                  </span>
                  <span className="ExplanationsPanel__ListItemValue">Unutilized space</span>
                </li>

                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon Icons__Icon--contract-option"></span>
                  <span className="ExplanationsPanel__ListItemValue">Contract option</span>
                </li>
                <li className="ExplanationsPanel__ListItem">
                  <span className="Icons__Icon Icons__Icon--no-contract"></span>
                  <span className="ExplanationsPanel__ListItemValue">No contract</span>
                </li>
              </ul>
            </div>
            {additionalLegendInfo && (
              <div className="ExplanationsPanel__Part Icons">
                <h3 className="ExplanationsPanel__Heading">Info</h3>
                <p style={{ maxWidth: "500px", whiteSpace: "pre-wrap" }}>{additionalLegendInfo}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

CalendarLegend.propTypes = {
  timespan: PropTypes.object,
  accessLevel: PropTypes.number,
  dayWidth: PropTypes.number,
  openPanelWithType: PropTypes.func,
  regretChange: PropTypes.func,
  schedulerData: PropTypes.object,
  currentUser: PropTypes.object,
  application: PropTypes.object,
  domain: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    dayWidth: state.calendar.dayWidth,
    PVersion: state.calendar.PVersion,
    timespan: state.calendar.timespan,
    accessLevel: state.user.accessLevel,
    currentUser: state.application.user,
    schedulerData: state.schedulerData,
    application: state.application,
    domain: state.application.domain,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLegend)
