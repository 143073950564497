import PropTypes from "prop-types"
import React, { Component } from "react"
import EditDetails from "../EditDetails"

class ScrollContainer extends Component {
  shouldComponentUpdate = (prevProps) => {
    const { showExpanded, data, blocks, rigList, assets, failedStatusChange } = this.props

    if (showExpanded !== prevProps.showExpanded) {
      return true
    }

    if (data !== prevProps.data) {
      return true
    }

    if (blocks !== prevProps.blocks) {
      return true
    }

    if (rigList !== prevProps.rigList) {
      return true
    }

    if (assets !== prevProps.assets) {
      return true
    }

    if (failedStatusChange !== prevProps.failedStatusChange) {
      return true
    }

    return false
  }

  render() {
    const {
      onScroll,
      showExpanded,
      onFieldUpdate,
      data,
      blocks,
      rigList,
      assets,
      licenses,
      failedStatusChange,
      activeRigTypes,
      activeWellTypes,
      includeAfeCo2,
      scenarios,
      units,
      currency,
      user,
    } = this.props

    return (
      <div id="Details-edit" onScroll={onScroll} className="Details__expanded Details-edit">
        {showExpanded && (
          <EditDetails
            onFieldUpdate={onFieldUpdate}
            data={data}
            blocks={blocks}
            rigList={rigList}
            assets={assets}
            licenses={licenses}
            failedStatusChange={failedStatusChange}
            activeRigTypes={activeRigTypes}
            activeWellTypes={activeWellTypes}
            includeAfeCo2={includeAfeCo2}
            scenarios={scenarios}
            units={units}
            currency={currency}
            user={user}
          />
        )}
      </div>
    )
  }
}

ScrollContainer.propTypes = {
  onScroll: PropTypes.func,
  data: PropTypes.object,
  blocks: PropTypes.object,
  assets: PropTypes.object,
  licenses: PropTypes.object,
  scenarios: PropTypes.object,
  failedStatusChange: PropTypes.string,
  rigList: PropTypes.array,
  onFieldUpdate: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleCommentChange: PropTypes.func,
  classes: PropTypes.string,
  direction: PropTypes.string,
  showExpanded: PropTypes.bool,
  activeRigTypes: PropTypes.array,
  activeWellTypes: PropTypes.array,
  includeAfeCo2: PropTypes.bool,
  units: PropTypes.string,
  currency: PropTypes.string,
  user: PropTypes.object,
}

export default ScrollContainer
