export const AVAILABLE_APP_SETTINGS = [
  { value: "logo", label: "Company logo" },
  // { value: "names", label: "Names" },
  { value: "validEmails", label: "User Settings" },
  { value: "activeRigTypes", label: "Available Rig Types" },
  { value: "activeWellTypes", label: "Available Well Types" },
  { value: "scenarios", label: "Scenarios" },
  { value: "fields", label: "Well and Rig Editor Settings" },
  { value: "includeAfeCo2", label: "Use AFE/Co2 actual cost" },
  { value: "units", label: "Units" },
  { value: "currency", label: "Currency" },
  { value: "extendedViewerMonths", label: "Months available for extended viewers" },
  { value: "additionalLegendInfo", label: "Additional info, shown in the (i)-legend in the schedule" },
  { value: "wellStatusMapping", label: "Well status names" },
]
export const readableAppSetting = (option) => {
  // ["logo", "names", "validEmails", "activeRigTypes", "activeWellTypes", "p10", "p50", "p90", "fields"]

  return AVAILABLE_APP_SETTINGS.find((e) => e.value === option) ? AVAILABLE_APP_SETTINGS.find((e) => e.value === option).label : option
}
