import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../redux/actions"
import POH from "../../lib/WellPopoverHelper"
import DH from "../../lib/DomUtils"

class WellPopover extends Component {
  constructor(props) {
    super(props)

    this.popoverRef = React.createRef()

    this.state = {
      objectid: null,
      yOffset: 0,
    }
  }

  getSnapshotBeforeUpdate = (prevProps) => {
    const {
      calendar: { popOverData, zoomLevel, scrollPosition },
    } = this.props
    // Capture the scroll position so we can adjust scroll later.
    if (prevProps.calendar.zoomLevel !== zoomLevel) {
      return scrollPosition.object
    }
    if (popOverData !== prevProps.calendar.popOverData) {
      return "clear"
    }
    return null
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { yOffset } = this.state
    const hasPopOver = prevProps.hasPopover

    // adjust position for popup if it exceeds window bottom
    if (this.popoverRef.current && hasPopOver) {
      let offset = 0
      const box = this.popoverRef.current.getBoundingClientRect()
      const popBtm = box.bottom
      if (popBtm > window.innerHeight) {
        offset = box.height + 40
      }

      if (yOffset !== offset && offset !== 0) {
        this.setState({
          yOffset: offset,
        })
      }
    } else if (!hasPopOver && yOffset !== 0) {
      this.setState({
        yOffset: 0,
      })
    }

    if (snapshot !== null) {
      setTimeout(() => {
        this.setState({
          objectid: snapshot === "clear" ? null : snapshot,
        })
      }, 1)
    }
  }

  handleClickOutside = (event) => {
    const isZoomButton = event.target.classList.contains("Zoom--Button")

    if (this.popoverRef && this.popoverRef.current && !this.popoverRef.current.contains(event.target) && !isZoomButton) {
      const { closeWellPopover, deleteScrollObjectPosition } = this.props
      closeWellPopover()
      deleteScrollObjectPosition()
    }
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside)
  }

  getMiddleWellPosition = (id, wells) => {
    const { PVersion, dayWidth } = this.props

    let pos = 0
    let currentWitdh = 0

    for (let i = 0; i <= id; i += 1) {
      currentWitdh = wells[i].estimateData[PVersion.value].estimate * dayWidth
      if (i === id) {
        pos += currentWitdh / 2
      } else {
        pos += currentWitdh
      }
    }

    return pos
  }

  render() {
    const { objectid, yOffset } = this.state
    const {
      calendar: { popOver, popOverData },
    } = this.props

    if (!popOver) {
      return null
    }

    const posTop = Math.max(popOverData.pos.top + popOverData.pos.height + 12 - yOffset, 10)
    let posLeft
    const elm = document.getElementById(objectid)
    const parentPos = document.getElementById("Harry-Plotter").getBoundingClientRect()

    if (objectid && elm) {
      const translateXVal = DH.getTranslateValues(elm).translateX
      posLeft = translateXVal
    } else {
      posLeft = popOverData.pos.left
    }

    posLeft = posLeft - 150 + this.getMiddleWellPosition(popOverData.wellClickedID, popOverData.wells)

    // build class styles
    const seqence = popOverData.wells.length > 0
    let classes = seqence
      ? `WellPopover WellPopover__Container WellPopover--Sequence WellPopover--${popOverData.wells[popOverData.wellClickedID].status} `
      : `WellPopover WellPopover__Container WellPopover--${popOverData.booking_type ? popOverData.booking_type : popOverData.status}`

    if (posLeft < 4) {
      posLeft = 3
      classes += " left"
    }

    if (posLeft > parentPos.width) {
      posLeft = parentPos.width - 305
      classes += " right"
    }
    if (popOverData.pos.isRight === true) {
      classes += " right"
    }

    // hide arrow as its now above the well name
    if (yOffset !== 0) {
      classes += " hideArrow"
    }

    const el = (
      <div
        ref={this.popoverRef}
        // prevent syncScroll from executing, dont bubble up to parent
        onScroll={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        className={classes}
        style={{
          top: posTop,
          left: posLeft,
        }}
      >
        {POH.getPopover(popOver)}
      </div>
    )

    return el
  }
}

WellPopover.propTypes = {
  calendar: PropTypes.object,
  children: PropTypes.object,
  dayWidth: PropTypes.number,
  PVersion: PropTypes.object,
  hasPopover: PropTypes.bool,
  closeWellPopover: PropTypes.func,
  deleteScrollObjectPosition: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    calendar: state.calendar,
    dayWidth: state.calendar.dayWidth,
    PVersion: state.calendar.PVersion,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WellPopover)
