import {
  ADD_EXISTING_RIG_TO_SCHEDULE,
  API_ADD_BUFFER_TIME,
  API_REMOVE_BUFFER_TIME,
  API_REMOVE_RIG,
  CLEAR_DRAG_ELEMENT,
  CLEAR_SINGLE_WELL_DATA,
  CREATE_NEW_REQUEST,
  CREATE_NEW_RIG,
  DELETE_TEMPORARY_WELL,
  FETCH_AREA_DATA,
  FETCH_ASSETS_DATA,
  FETCH_BLOCK_DATA,
  FETCH_LICENSE_DATA,
  FETCH_RIG_DATA,
  FETCH_RIG_LIST,
  FETCH_RIG_REQUESTS,
  FETCH_SINGLE_WELL_DATA,
  FETCH_WELL_DATA,
  GET_CURRENT_SCHEDULE_SHARE_INFORMATION,
  GET_SHARE_GROUPS,
  NOTIFY_SHARES_SUCCESS,
  REGRET_CHANGE,
  REMOVE_CURRENT_SHARES,
  REMOVE_WELL_INSTANCE,
  RESET_RIG_DATA,
  SAVE_CURRENT_SHARES,
  SAVE_SHARE_GROUPS,
  SAVE_WELL_INSTANCE,
  SET_BUFFER_TIME_INSTANCE_TO_REMOVE,
  SET_CURRENT_SCHEDULE_AS_MASTER,
  SET_DRAG_ROW_ID,
  SET_DROP_PRIORITY,
  SET_DROP_READY,
  SET_ELEMENT_IN_BETWEEN,
  SET_OVERRIDE_P_VALUE_FOR_WELL,
  SET_TEMPORARY_WELL,
  SET_WELL_INSTANCE_TO_REMOVE,
  SHARE_CURRENT_SCHEDULE,
  TOGGLE_DRAGGING,
  UPDATE_AREA_DATA,
  UPDATE_ASSETS_DATA,
  UPDATE_BLOCK_DATA,
  UPDATE_DRAG_POS,
  UPDATE_LICENSE_DATA,
  UPDATE_REGRET_DATA,
  UPDATE_RIG_DATA,
  UPDATE_RIG_DATA_EDIT,
  UPDATE_RIG_LIST,
  UPDATE_RIG_PRIORITY,
  UPDATE_RIG_REQUESTS,
  UPDATE_SCHEDULE_BASED_ON_PRIORITY,
  UPDATE_SINGLE_WELL_DATA,
  UPDATE_TEMPORARY_WELL,
  UPDATE_WELL_DATA,
} from "../types"

export function fetchRigData() {
  return { type: FETCH_RIG_DATA }
}

export function fetchBlockData(id) {
  return { type: FETCH_BLOCK_DATA, id }
}
export function setOverridePValueForWell(data) {
  return { type: SET_OVERRIDE_P_VALUE_FOR_WELL, data }
}

export function fetchWellData() {
  return { type: FETCH_WELL_DATA }
}

export function fetchSingleWellInstance(id) {
  return { type: FETCH_SINGLE_WELL_DATA, id }
}

export function clearSingleWellToAddToRigBooking() {
  return { type: CLEAR_SINGLE_WELL_DATA }
}
export function updateWellData(data) {
  return { type: UPDATE_WELL_DATA, data }
}
export function updateSingleWellInstance(data) {
  return { type: UPDATE_SINGLE_WELL_DATA, data }
}
export function fetchAssetsData(id) {
  return { type: FETCH_ASSETS_DATA, id }
}
export function fetchAreaData() {
  return { type: FETCH_AREA_DATA }
}
export function updateAreaData(data) {
  return { type: UPDATE_AREA_DATA, data }
}
export function fetchLicenseData(id) {
  return { type: FETCH_LICENSE_DATA, id }
}
export function updateBlockData(data) {
  return { type: UPDATE_BLOCK_DATA, data }
}
export function updateAssetsData(data) {
  return { type: UPDATE_ASSETS_DATA, data }
}
export function updateLicensesData(data) {
  return { type: UPDATE_LICENSE_DATA, data }
}

export function fetchRigRequests() {
  return { type: FETCH_RIG_REQUESTS }
}

export function fetchRigList() {
  return { type: FETCH_RIG_LIST }
}

export function updateRigList(data) {
  return { type: UPDATE_RIG_LIST, data }
}

export function updateRigData(data) {
  return { type: UPDATE_RIG_DATA, data }
}
export function resetRigdataEdit() {
  return { type: RESET_RIG_DATA }
}

export function createNewRig(data) {
  return { type: CREATE_NEW_RIG, data }
}
export function saveWellInstance(data) {
  return { type: SAVE_WELL_INSTANCE, data }
}
export function setRigPriority(data) {
  return { type: UPDATE_RIG_PRIORITY, data }
}
export function removeWellInstance() {
  return { type: REMOVE_WELL_INSTANCE }
}
export function setWellInstanceToRemove(data) {
  return { type: SET_WELL_INSTANCE_TO_REMOVE, data }
}

export function removeRig(data) {
  return { type: API_REMOVE_RIG, data }
}

export function addExistingRigToSchedule(data) {
  return { type: ADD_EXISTING_RIG_TO_SCHEDULE, data }
}

export function addBufferTime(data) {
  return { type: API_ADD_BUFFER_TIME, data }
}

export function setBufferTimeInstanceToRemove(data) {
  return { type: SET_BUFFER_TIME_INSTANCE_TO_REMOVE, data }
}

export function removeBufferTimeInstance(data) {
  return { type: API_REMOVE_BUFFER_TIME, data }
}

export function createNewRequest(data) {
  return { type: CREATE_NEW_REQUEST, data }
}

export function setCurrentScheduleAsMaster(data) {
  return { type: SET_CURRENT_SCHEDULE_AS_MASTER, data }
}

export function getCurrentScheduleShareInformation() {
  return { type: GET_CURRENT_SCHEDULE_SHARE_INFORMATION }
}

export function getShareGroups() {
  return { type: GET_SHARE_GROUPS }
}

export function saveShareGroups(groups) {
  return { type: SAVE_SHARE_GROUPS, groups }
}

export function notifySharesSuccess(success) {
  return { type: NOTIFY_SHARES_SUCCESS, success }
}

export function saveCurrentShares(shareList) {
  return { type: SAVE_CURRENT_SHARES, shareList }
}

export function removeSharedToUsers(removeList) {
  return { type: REMOVE_CURRENT_SHARES, removeList }
}

export function shareCurrentSchedule(shareList) {
  return { type: SHARE_CURRENT_SCHEDULE, shareList }
}

export function updateRigDataEdit() {
  return { type: UPDATE_RIG_DATA_EDIT }
}

export function updateRigRequests(data) {
  return { type: UPDATE_RIG_REQUESTS, data }
}

export function regretChange() {
  return { type: REGRET_CHANGE }
}

export function updateRegretData(data) {
  return { type: UPDATE_REGRET_DATA, data }
}

export function setDropReady(dropReady) {
  return { type: SET_DROP_READY, dropReady }
}

export function setElementInBetween(data) {
  return { type: SET_ELEMENT_IN_BETWEEN, data }
}

export function isDragging(dragging) {
  return {
    type: TOGGLE_DRAGGING,
    isDragging: dragging,
  }
}
export function updateDragPos(xPos) {
  return {
    type: UPDATE_DRAG_POS,
    xPos,
  }
}

export function setTemporaryWell(dom, data) {
  return {
    type: SET_TEMPORARY_WELL,
    dom,
    data,
  }
}

export function clearDragElement() {
  return {
    type: CLEAR_DRAG_ELEMENT,
  }
}

export function deleteTemporaryWell() {
  return { type: DELETE_TEMPORARY_WELL }
}

export function setDragRow(id) {
  return { type: SET_DRAG_ROW_ID, id }
}

export function setDropInfo(dropInfo) {
  return { type: SET_DROP_PRIORITY, dropInfo }
}

export function updateScheduleBasedOnWellPriority() {
  return { type: UPDATE_SCHEDULE_BASED_ON_PRIORITY }
}

export function updateTemporaryWell(scheduleVersionId, toRigId, posX, dayWidth, startDate) {
  return {
    type: UPDATE_TEMPORARY_WELL,
    scheduleVersionId,
    toRigId,
    posX,
    dayWidth,
    startDate,
  }
}
