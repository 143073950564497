import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class OverridePValuesPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: "",
      selectedP10Items: [],
      selectedP90Items: [],
    }
  }

  componentDidMount() {
    const { getWellList, overridenPValueWells } = this.props
    getWellList()

    if (overridenPValueWells) {
      const selectedP10Items = Object.keys(overridenPValueWells)
        .filter((item) => item.pValue === "p10")
        .map((item) => item.wellId)
      const selectedP90Items = Object.keys(overridenPValueWells)
        .filter((item) => item.pValue === "p90")
        .map((item) => item.wellId)
      this.setState({ selectedP10Items, selectedP90Items })
    }
  }

  doUpdate = () => {
    const { setOverridePValueForWell, onClose, fetchRigData } = this.props
    const { selectedP10Items, selectedP90Items } = this.state
    setOverridePValueForWell([
      ...selectedP10Items.map((item) => ({ wellId: item, pValue: "p10" })),
      ...selectedP90Items.map((item) => ({ wellId: item, pValue: "p90" })),
    ])
    fetchRigData()
    onClose()
  }
  reset = () => {
    const { setOverridePValueForWell, onClose, fetchRigData } = this.props
    setOverridePValueForWell([])
    this.setState({ selectedP10Items: [], selectedP90Items: [] })
    fetchRigData()
    onClose()
  }

  filter = (event) => {
    this.setState({ filter: event.target.value })
  }

  render() {
    const { filter, selectedP10Items, selectedP90Items } = this.state
    const { wells, onClose } = this.props

    const itemList = filter
      ? wells.filter((v) => {
          const n = v.name || ""
          const exist = n.replace(/\//g, "===").toLowerCase().indexOf(filter.replace(/\//g, "===").toLowerCase()) > -1
          return exist
        })
      : wells

    return (
      <div className="content">
        <div className={`ContentList`} style={{ width: "100%", maxWidth: "none" }}>
          <div className="ContentList__HeaderWrapper">
            <h1 className="ContentList__Header">Override P Values</h1>
            <input onChange={this.filter} className={"ContentList__Search"} placeholder="Filter" />
          </div>
          <div className="ContentList__Content">
            {itemList &&
              itemList.map((b, k) => {
                const isP10 = selectedP10Items.includes(b.id)
                const isP90 = selectedP90Items.includes(b.id)
                return (
                  <div key={k} className="ContentList__ListItem">
                    <span className="ContentList__ItemTitle">{b.name}</span>
                    <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
                      {isP10 && <span className="ContentList__ItemTitle">p10</span>}
                      {isP90 && <span className="ContentList__ItemTitle">p90</span>}
                      {isP10 && (
                        <button
                          onClick={() =>
                            this.setState({
                              selectedP10Items: selectedP10Items.filter((item) => item !== b.id),
                            })
                          }
                          className="ContentList__EditBtn"
                        >
                          Remove p10
                        </button>
                      )}
                      {isP90 && (
                        <button
                          onClick={() =>
                            this.setState({
                              selectedP90Items: selectedP90Items.filter((item) => item !== b.id),
                            })
                          }
                          className="ContentList__EditBtn"
                        >
                          Remove p90
                        </button>
                      )}
                      {!isP10 && !isP90 && (
                        <>
                          <button
                            onClick={() =>
                              selectedP10Items.includes(b.id)
                                ? this.setState({
                                    selectedP10Items: selectedP10Items.filter((item) => item !== b.id),
                                  })
                                : this.setState({
                                    selectedP10Items: [...this.state.selectedP10Items, b.id],
                                  })
                            }
                            className="ContentList__EditBtn"
                          >
                            Show p10
                          </button>
                          <button
                            onClick={() =>
                              this.setState({
                                selectedP90Items: [...this.state.selectedP90Items, b.id],
                              })
                            }
                            className="ContentList__EditBtn"
                          >
                            Show p90
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="ContentList__Footer">
            <button onClick={this.reset} className={`ContentList__RemoveBtn show`}>
              Reset all wells to default
            </button>
            <div>
              <button onClick={() => onClose()} className={`ContentList__RemoveBtn show`}>
                Dismiss
              </button>
              <button onClick={this.doUpdate} className={`ContentList__RemoveBtn show`}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OverridePValuesPanel.propTypes = {
  overridenPValueWells: PropTypes.object,
  getWellList: PropTypes.func,
  setOverridePValueForWell: PropTypes.func,
  onClose: PropTypes.func,
  wells: PropTypes.array,
  fetchRigData: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    overridenPValueWells: state.schedulerData.overridenPValueWells,
    wells: state.managecontent.wells,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverridePValuesPanel)
