import parseISO from "date-fns/parseISO"
import { cloneDeep, find, remove } from "lodash"
import store from "store"

import createReducer from "../../lib/createReducer"
import * as types from "../types"

export const initialState = {
  fetchRigDataIndex: 0,
  fetchRigRequests: 0,
  fetchRigLists: 0,
  isDragging: false,
  dragRowID: 0,
  schedulerData: {},
  schedulerDataEdit: {},
  schedulerHistoryObject: null,
  schedulerHistory: new Map(),
  dropReady: false,
  sendInBetween: null,
  rigRequests: {},
  wellInstance: {},
  wellInstanceToRemove: {},
  blocks: {},
  assets: {},
  licenses: {},
  areas: [],
  wells: [],
  bufferTime: {},
  removeBufferTime: {},
  updateRigDataIndex: 0,
  moveObject: {},
  existingRigToAdd: {},
  rigPriorityArray: [],
  removeRigInstanceIds: [],
  newRigData: {
    rigData: {},
    schedule_version_id: null,
  },
  currentShareList: {},
  currentRemoveShareList: {},
  shareGroups: [],
  wasNotificationASuccess: null,
  shareTo: {
    message: "",
    users: [],
  },
  xPos: 0,
  xPosDiff: 0,
  dropInfo: null,
  singleIdWellToFetch: 0,
  singleWellToAddToRigBooking: null,
  overridenPValueWells: {},
}

export const schedulerData = createReducer(initialState, {
  [types.FETCH_RIG_DATA](state) {
    const ns = Object.assign({}, state)
    ns.fetchRigDataIndex += 1
    return ns
  },
  [types.FETCH_ASSETS_DATA](state) {
    return state
  },
  [types.FETCH_AREA_DATA](state) {
    return state
  },
  [types.FETCH_LICENSE_DATA](state) {
    return state
  },
  [types.FETCH_BLOCK_DATA](state) {
    return state
  },
  [types.FETCH_WELL_DATA](state) {
    return state
  },
  [types.FETCH_SINGLE_WELL_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.singleIdWellToFetch = actions.id
    return ns
  },
  [types.UPDATE_SINGLE_WELL_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.singleWellToAddToRigBooking = actions.data
    return ns
  },
  [types.CLEAR_SINGLE_WELL_DATA](state) {
    const ns = Object.assign({}, state)
    ns.singleWellToAddToRigBooking = null
    return ns
  },
  [types.SET_CURRENT_SCHEDULE_AS_MASTER](state) {
    return state
  },
  [types.GET_CURRENT_SCHEDULE_SHARE_INFORMATION](state) {
    return state
  },
  [types.GET_SHARE_GROUPS](state) {
    return state
  },
  [types.SAVE_SHARE_GROUPS](state, actions) {
    const ns = Object.assign({}, state)
    ns.shareGroups = actions.groups
    return ns
  },
  [types.SAVE_CURRENT_SHARES](state, actions) {
    const ns = Object.assign({}, state)
    ns.currentShareList = actions.shareList
    return ns
  },
  [types.REMOVE_CURRENT_SHARES](state, actions) {
    const ns = Object.assign({}, state)
    ns.currentRemoveShareList = {
      users: actions.removeList,
    }
    return ns
  },
  [types.NOTIFY_SHARES_SUCCESS](state, actions) {
    const ns = Object.assign({}, state)
    ns.wasNotificationASuccess = actions.success
    ns.currentRemoveShareList = {}

    return ns
  },
  [types.SHARE_CURRENT_SCHEDULE](state, actions) {
    const ns = Object.assign({}, state)

    const newShareTo = cloneDeep(ns.ShareTo) || {}

    newShareTo.message = actions.shareList.comment ? actions.shareList.comment : ""
    newShareTo.users = Object.keys(actions.shareList.users)

    ns.shareTo = newShareTo

    ns.wasNotificationASuccess = null

    return ns
  },
  [types.OBSERVER_EMPTY](state) {
    return state
  },
  [types.UPDATE_WELL_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.wells = actions.data
    return ns
  },
  [types.UPDATE_AREA_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.areas = actions.data
    return ns
  },
  [types.ADD_EXISTING_RIG_TO_SCHEDULE](state, actions) {
    const ns = Object.assign({}, state)
    ns.existingRigToAdd = actions.data
    return ns
  },
  [types.UPDATE_RIG_PRIORITY](state, actions) {
    const ns = Object.assign({}, state)
    ns.rigPriorityArray = actions.data
    return ns
  },
  [types.UPDATE_ASSETS_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.assets = actions.data
    return ns
  },
  [types.UPDATE_LICENSE_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.licenses = actions.data
    return ns
  },
  [types.UPDATE_BLOCK_DATA](state, actions) {
    const ns = Object.assign({}, state)
    ns.blocks = actions.data
    return ns
  },
  [types.API_ADD_BUFFER_TIME](state, actions) {
    const ns = Object.assign({}, state)
    ns.bufferTime = actions.data
    return ns
  },
  [types.API_REMOVE_BUFFER_TIME](state) {
    return state
  },
  [types.SET_BUFFER_TIME_INSTANCE_TO_REMOVE](state, actions) {
    const ns = Object.assign({}, state)
    ns.removeBufferTime = actions.data
    return ns
  },
  [types.API_REMOVE_RIG](state, actions) {
    const ns = Object.assign({}, state)
    ns.removeRigInstanceIds = actions.data
    return ns
  },
  [types.FETCH_RIG_REQUESTS](state) {
    const ns = Object.assign({}, state)
    ns.fetchRigRequests += 1

    return ns
  },
  [types.FETCH_RIG_LIST](state) {
    const ns = Object.assign({}, state)
    ns.fetchRigLists += 1

    return ns
  },
  [types.UPDATE_RIG_LIST](state, actions) {
    const ns = Object.assign({}, state)

    ns.rigList = actions.data.data

    return ns
  },
  [types.CREATE_NEW_RIG](state, actions) {
    const ns = Object.assign({}, state)

    const params = actions.data

    const scheduleVersionArr = params.schedule_version_id

    delete params.schedule_version_id

    ns.newRigData.rigData = params
    ns.newRigData.schedule_version_id = scheduleVersionArr

    return ns
  },
  [types.UPDATE_REGRET_DATA](state, actions) {
    const ns = Object.assign({}, state)

    const prevData = actions.data

    prevData.data.created_at = parseISO(prevData.data.created_at)
    prevData.data.end_date = parseISO(prevData.data.end_date)
    prevData.data.start_date = parseISO(prevData.data.start_date)
    prevData.data.updated_at = parseISO(prevData.data.updated_at)

    if (Object.keys(prevData).length > 0) {
      const draftID = ns.schedulerData.data.id
      const allHistory = new Map()

      Object.keys(ns.schedulerHistory, (key) => {
        allHistory.set(key, Object.create(ns.schedulerHistory[key]))
      })

      const history = allHistory.get(draftID) || []
      history.unshift(prevData)

      if (history.length > 20) {
        history.pop()
      }

      allHistory.set(draftID, history)
      ns.schedulerHistory = allHistory
    }

    return ns
  },
  [types.UPDATE_RIG_DATA](state, actions) {
    const ns = Object.assign({}, state)

    ns.schedulerData = actions.data
    ns.schedulerDataEdit = actions.data

    store.set("RIG_schedulerDataVersion", {
      id: actions.data.data.id,
      revision: actions.data.data.revision,
    })

    ns.moveObject = {}
    ns.updateRigDataIndex += 1
    return ns
  },
  [types.SET_OVERRIDE_P_VALUE_FOR_WELL](state, actions) {
    const ns = Object.assign({}, state)

    if (actions.data.length === 0) {
      ns.overridenPValueWells = {}
      return ns
    }

    const overridenPValueWells = {}
    for (const well of actions.data) {
      overridenPValueWells[well.wellId] = well.pValue
    }

    ns.overridenPValueWells = overridenPValueWells
    return ns
  },
  [types.REGRET_CHANGE](state) {
    const ns = Object.assign({}, state)
    const draftID = ns.schedulerData.data.id

    const allHistory = new Map(ns.schedulerHistory)
    const history = allHistory.get(draftID) || []

    const regretToData = history.shift()

    allHistory.set(draftID, history)

    ns.schedulerHistory = allHistory
    ns.schedulerDataEdit = regretToData

    return ns
  },
  [types.SAVE_WELL_INSTANCE](state, actions) {
    const ns = Object.assign({}, state)
    ns.wellInstance = actions.data

    return ns
  },
  [types.SET_WELL_INSTANCE_TO_REMOVE](state, actions) {
    const ns = Object.assign({}, state)
    ns.wellInstanceToRemove = actions.data

    return ns
  },
  [types.REMOVE_WELL_INSTANCE](state) {
    const ns = Object.assign({}, state)
    return ns
  },
  [types.CREATE_NEW_REQUEST](state, actions) {
    const ns = Object.assign({}, state)

    const params = actions.data
    ns.newRequestData = params

    return ns
  },
  [types.RESET_RIG_DATA](state) {
    const ns = Object.assign({}, state)
    const sd = cloneDeep(ns.schedulerData)

    ns.schedulerDataEdit = sd
    ns.fetchRigDataIndex += 1

    ns.moveObject = {}

    return ns
  },
  [types.UPDATE_RIG_DATA_EDIT](state) {
    const ns = Object.assign({}, state)

    // console.log('UPDATE_RIG_DATA_EDIT')

    const { rigId, toRigId, id, startDate } = ns.moveObject

    const schedulerHistoryObject = cloneDeep(ns.schedulerData)
    ns.schedulerHistoryObject = schedulerHistoryObject

    let entry = null

    // TODO: Call SET_TEMPORARY_WELL and get and save it with an observer
    const { rigs } = ns.schedulerDataEdit.data

    const fromRig = find(rigs, (obj) => obj.rig_id === rigId)
    const toRig = find(rigs, (obj) => obj.rig_id === toRigId)

    if (fromRig !== toRig) {
      entry = remove(fromRig.entries, (obj) => obj.id === id)[0]

      entry.start_date = startDate
      toRig.entries.push(entry)
    } else {
      entry = find(fromRig.entries, (obj) => obj.id === id)
      entry.start_date = startDate
    }

    ns.schedulerDataEdit.data.rigs = rigs

    return ns
  },
  [types.UPDATE_RIG_REQUESTS](state, actions) {
    const ns = Object.assign({}, state)
    ns.rigRequests = actions.data

    return ns
  },

  // EDIT
  [types.TOGGLE_DRAGGING](state, actions) {
    const ns = Object.assign({}, state)
    ns.isDragging = actions.isDragging
    return ns
  },
  [types.SET_DRAG_ROW_ID](state, actions) {
    const ns = Object.assign({}, state)
    ns.dragRowID = actions.id
    return ns
  },
  [types.UPDATE_DRAG_POS](state, actions) {
    const ns = Object.assign({}, state)
    ns.xPosDiff = Math.abs(ns.xPos - actions.xPos)
    ns.xPos = actions.xPos
    return ns
  },
  [types.SET_DROP_READY](state, actions) {
    const ns = Object.assign({}, state)
    ns.dropReady = actions.dropReady

    return ns
  },
  [types.SET_ELEMENT_IN_BETWEEN](state, actions) {
    const ns = Object.assign({}, state)
    ns.sendInBetween = actions.data
    const schedulerHistoryObject = cloneDeep(ns.schedulerData)
    ns.schedulerHistoryObject = schedulerHistoryObject

    return ns
  },
  [types.SET_TEMPORARY_WELL](state, actions) {
    const ns = Object.assign({}, state)

    ns.moveObject = {
      dom: actions.dom,
      ...actions.data,
    }

    return ns
  },
  [types.CLEAR_DRAG_ELEMENT](state) {
    const ns = Object.assign({}, state)

    ns.moveObject = {}

    return ns
  },
  [types.DELETE_TEMPORARY_WELL](state) {
    const ns = Object.assign({}, state)

    ns.moveObject = {}
    return ns
  },
  [types.SET_DROP_PRIORITY](state, actions) {
    const ns = Object.assign({}, state)

    ns.dropInfo = actions.dropInfo

    return ns
  },
  [types.UPDATE_SCHEDULE_BASED_ON_PRIORITY](state) {
    const ns = Object.assign({}, state)
    const dropInfo = cloneDeep(ns.dropInfo)

    ns.dropInfo = dropInfo

    return ns
  },
  [types.UPDATE_TEMPORARY_WELL](state, actions) {
    if (!state.moveObject) {
      return state
    }

    // console.log('UPDATE_TEMPORARY_WELL')

    // GET STATE
    const ns = Object.assign({}, state)
    const moveObject = cloneDeep(ns.moveObject)

    moveObject.scheduleVersionId = actions.scheduleVersionId
    moveObject.toRigId = actions.toRigId
    moveObject.posX = actions.posX
    moveObject.dayWidth = actions.dayWidth
    moveObject.startDate = actions.startDate
    moveObject.priority = actions.priority

    ns.moveObject = moveObject

    // console.log('save this moveObject:', moveObject);

    return ns
  },
})
