import addDays from "date-fns/addDays"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import WellPopoverMetaInfo from "../../../components/PopOver/wellPopoverMetaInfo"
import Chevron from "../../../components/icons/Chevron"
import IconContainer from "../../../components/icons/IconContainer"
import { DATE_FORMATE_NB_SHORT } from "../../../dateConsts"
import CU from "../../../lib/CalendarUtils"
import IU from "../../../lib/IconUtils"
import { EDIT_WELL_CAMPAIGN } from "../../../panelConsts"
import { ActionCreators } from "../../../redux/actions"

class Wells extends Component {
  constructor(props) {
    super(props)

    this.scrollRef = React.createRef()

    this.state = {
      scrollY: 0,
      bottom: false,
      noScroll: false,
    }
  }

  editCampaign = () => {
    const { openPanelWithType, popOverData, closeWellPopover } = this.props
    openPanelWithType(EDIT_WELL_CAMPAIGN, popOverData)

    closeWellPopover()
  }

  setOverridePValue = (pValue) => {
    const { popOverData, setOverridePValueForWell } = this.props
    setOverridePValueForWell({
      wellId: popOverData.wells[popOverData.wellClickedID].well.id,
      pValue: pValue,
    })
  }

  closeAndDeselect = () => {
    const { closeWellPopover, deleteScrollObjectPosition } = this.props

    closeWellPopover()
    deleteScrollObjectPosition()
  }

  componentDidMount() {
    // this.setState({ scrollY: e.target.scrollTop })
    // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight

    if (this.scrollRef && this.scrollRef.current) {
      const bottom = this.scrollRef.current.scrollHeight - this.scrollRef.current.scrollTop === this.scrollRef.current.clientHeight
      if (bottom) {
        this.setState({ noScroll: true })
      }
      // console.log(e.target)
    }

    // this.setState({ bottom })
  }

  onScroll = (e) => {
    this.setState({ scrollY: e.target.scrollTop })
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    this.setState({ bottom })
  }

  render() {
    const { popOverData, readonly, usersAsset, accessLevel, PVersion, mapUrl, user, overridenPValueWells } = this.props

    const wells = [popOverData.wells[popOverData.wellClickedID]]
    let daysFromSequenceStart = 0
    let daysFromSequenceEnd = 0

    if (popOverData.wellClickedID > 0) {
      for (let i = 0; i < popOverData.wellClickedID; i += 1) {
        daysFromSequenceStart += popOverData.wells[i].estimateData[PVersion.value].estimate
      }
    }

    daysFromSequenceEnd = daysFromSequenceStart + popOverData.wells[popOverData.wellClickedID].estimateData[PVersion.value].estimate

    const estimateData = popOverData.estimateData[PVersion.value]
    const startDate = estimateData.start_date ? estimateData.start_date : new Date().toISOString()

    const startDateForSequence = new Date(startDate)
    const startDateForWell = daysFromSequenceStart > 0 ? addDays(startDateForSequence, daysFromSequenceStart) : startDateForSequence
    const endDateForWell = addDays(startDateForSequence, daysFromSequenceEnd)

    const canEdit = readonly ? false : CU.canCurrentUserEditWell(usersAsset, accessLevel, wells[0])

    let headline = ""

    wells.forEach((val) => {
      let name = val.well.name ? val.well.name : val.well.plno
      if (!name) {
        name = val.well.short_name
      }
      name = headline.length ? `, ${name}` : name
      headline += name ? `${name}` : "null"
    })

    const { well } = wells[0]
    const { pressure, request } = well

    const blockName = well.block ? well.block.name : well.plno
    const licenceName = well.license ? well.license.name : ""

    const rigMoveDays = popOverData.rig_move ? popOverData.rig_move.estimate : 0

    const entryStartDate = popOverData.calculated_start_date ? popOverData.calculated_start_date : startDate

    const entryStartDateObj = parseISO(entryStartDate)
    const wellStartDate = addDays(entryStartDateObj, Number(rigMoveDays))
    const wellEndDate = addDays(wellStartDate, Number(estimateData.estimate))

    const typeIcons = request ? request.rig_type : null

    const entryStartDateText = entryStartDate
      ? `Well start date: ${format(wellStartDate, DATE_FORMATE_NB_SHORT)}`
      : "There is no start date"

    const days = wells[0].estimateData[PVersion.value].estimate
    const daysInTotal = popOverData.rig_move ? Number(estimateData.estimate) + Number(popOverData.rig_move.estimate) : estimateData.estimate

    const estimatedFirstProductionDate = well.days_to_first_production ? addDays(wellEndDate, Number(well.days_to_first_production)) : null
    return (
      <div onScroll={this.onScroll} ref={this.scrollRef} className="WellPopover__Inner">
        {overridenPValueWells[well.id] ? (
          <div className="WellPopover__OverridePValue">
            <span>Showing {overridenPValueWells[well.id]}</span>
          </div>
        ) : null}
        <div className="WellPopover__Header">
          <div className="WellPopover__Heading">
            <h3 className="WellPopover__Headline">{headline}</h3>
            <div className="WellPopover__SubHeading WellPopover__SubHeading--small">
              {wells.length && wells[0].well.uwi ? `#${wells[0].well.uwi}` : ""}
            </div>
          </div>
          <div className="WellPopover__HeadingDuration">
            <span>
              {typeIcons ? typeIcons.map((val) => IconContainer(IU.getRigIcon(val), 11, 11)) : null}
              {pressure ? IconContainer(IU.getRigIcon(pressure), 7, 11) : null}
              {request && request.has_contract_with ? IconContainer(IU.getRigIcon("contract"), 9.25, 11) : null}
            </span>
            {wells[0].actual_duration ? (
              <div className="WellPopover__Duration WellPopover__Duration--actual">
                <div className="Well__Duration">
                  <span className="WellPopover__DurationText">{wells[0].actual_duration}</span>
                  <span className="WellPopover__DurationLabel">Actual duration</span>
                </div>
                {/* <div className="Well__Duration">
                  <span className="WellPopover__DurationText">{days}</span>
                  <span className="WellPopover__DurationLabel">Estimate</span>
                </div> */}
              </div>
            ) : (
              <div className="WellPopover__Duration">
                <div className="Well__Duration">
                  <span className="WellPopover__DurationText">{days}</span>
                  <span className="WellPopover__DurationLabel">Days</span>
                </div>
              </div>
            )}
            {popOverData.wells.length > 1 ? <span className="WellPopover__TotalDurationLabel">{`Total: ${daysInTotal} days`}</span> : null}
          </div>
        </div>
        <div className="WellPopover__Content" style={popOverData.rig_move ? { paddingLeft: "0" } : { paddingLeft: "15px" }}>
          <div className="WellPopover__ContentPart WellPopover__ContentPart--twofold">
            <div className="WellPopover__ContentText" style={popOverData.rig_move ? { paddingLeft: "15px" } : { paddingLeft: "0" }}>
              <div>
                <span className="FontStyle--Bold">{popOverData.wells.length > 1 ? "Well: " : "Well:"} </span>
                {`${format(startDateForWell, DATE_FORMATE_NB_SHORT)} - ${format(endDateForWell, DATE_FORMATE_NB_SHORT)}`}
              </div>

              {popOverData.wells.length > 1 ? (
                <div>
                  <span className="FontStyle--Bold">Sequence: </span>
                  {`${format(entryStartDateObj, DATE_FORMATE_NB_SHORT)} - ${format(wellEndDate, DATE_FORMATE_NB_SHORT)}`}
                </div>
              ) : null}
              {popOverData.rig_move ? <span className="WellPopover__WellStartDate">{entryStartDateText}</span> : null}
              {well.days_to_first_production && (
                <div>
                  <span className="FontStyle--Bold">First production date: </span>
                  <span>{format(estimatedFirstProductionDate, DATE_FORMATE_NB_SHORT)}</span>
                </div>
              )}
            </div>
            <div
              className="WellPopover__ContentText WellPopover__ContentText--rigmove Popover-Estimate"
              style={popOverData.rig_move ? {} : { border: "0" }}
            >
              {popOverData.rig_move ? (
                <>
                  <span className="Popover-Estimate__Rigmove">{popOverData.rig_move.estimate}</span>
                  <span className="Popover-Estimate__Well">{estimateData.estimate}</span>
                </>
              ) : (
                "\u00a0"
              )}
            </div>
          </div>
          {well.created_by && user.role === "Creator" && (
            <div className={"WellPopover__ContentPart"}>
              <div className={"WellPopover__ContentText"}>
                <span>Created by: </span>
                <span>{well.created_by}</span>
              </div>
            </div>
          )}
          {well.contact_person && (
            <div className={"WellPopover__ContentPart"}>
              <div className={"WellPopover__ContentText"}>
                <span>Contact person: </span>
                <span>{well.contact_person}</span>
              </div>
            </div>
          )}
          {well.expected_bpd && (
            <div className={"WellPopover__Expected"}>
              {well.expected_bpd && (
                <div className={"WellPopover__ExpectedPart"}>
                  <span>Expected production: </span>
                  <span>{well.expected_bpd} Bpd</span>
                </div>
              )}
            </div>
          )}
          {(blockName || licenceName) && (
            <div style={popOverData.rig_move ? { paddingLeft: "15px" } : {}} className={"WellPopover__Location"}>
              <div className={"WellPopover__Location--Header FontStyle--Bold"}>{"Location"}</div>
              {mapUrl ? (
                <a href={"https://www.akerbp.com/map/"} rel="noopener noreferrer" target="_blank" className="WellPopover__Location--Link">
                  <div>{`${blockName}-${licenceName}`}</div>
                </a>
              ) : (
                <div>{`${blockName}-${licenceName}`}</div>
              )}
            </div>
          )}
          {wells
            ? wells.map((w) => (
                <WellPopoverMetaInfo
                  isSingleWell={wells.length === 1}
                  hasRigMove={!!popOverData.rig_move}
                  key={`popoverwell-${w.id}`}
                  well={w}
                />
              ))
            : null}

          {/* <div className="WellPopover__Toggles">
            <button onClick={() => this.setOverridePValue("p10")} className={"WellPopover__ToggleBtn"}>
              Show P10
            </button>
            <button onClick={() => this.setOverridePValue("p50")} className={"WellPopover__ToggleBtn"}>
              Show P50
            </button>
            <button onClick={() => this.setOverridePValue("p90")} className={"WellPopover__ToggleBtn"}>
              Show P90
            </button>
          </div> */}
        </div>

        <div className="WellPopover__Buttons">
          <button onClick={this.closeAndDeselect} className="WellPopover__Button">
            Close
          </button>
          {canEdit
            ? !overridenPValueWells[well.id] && (
                <button onClick={this.editCampaign} className={"WellPopover__Button WellPopover__Button--primary"}>
                  Edit
                </button>
              )
            : null}
        </div>
        <div
          className={`WellPopover__Arrow${this.state.scrollY > 0 && !this.state.bottom ? " hidden" : ""}${
            this.state.noScroll ? " hidden" : ""
          }`}
        >
          {Chevron(this.state.bottom ? `open` : "", true)}
        </div>
      </div>
    )
  }
}

Wells.propTypes = {
  popOverData: PropTypes.object,
  deleteScrollObjectPosition: PropTypes.func,
  closeWellPopover: PropTypes.func,
  openPanelWithType: PropTypes.func,
  readonly: PropTypes.bool,
  user: PropTypes.object,
  mapUrl: PropTypes.string,
  accessLevel: PropTypes.number,
  usersAsset: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  PVersion: PropTypes.object,
  overridenPValueWells: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    popOverData: state.calendar.popOverData,
    readonly: state.schedulerData.schedulerData.data.readonly,
    accessLevel: state.user.accessLevel,
    usersAsset: state.user.asset,
    user: state.application.user,
    mapUrl: state.application.mapUrl,
    PVersion: state.calendar.PVersion,
    overridenPValueWells: state.schedulerData.overridenPValueWells,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wells)
