import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import parseISO from "date-fns/parseISO"

import Arrow from "../../../icons/Arrow"
import CU from "../../../../lib/CalendarUtils"
import IU from "../../../../lib/IconUtils"

import IconContainer from "../../../icons/IconContainer"

class WellBooking extends PureComponent {
  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    const { estimate, id, rigId, timespan, PVersion, isOverlapping, styles, hasRigMove, zoomLevel } = props

    this.state = {
      estimate,
      id,
      rigId,
      timespan,
      PVersion,
      isOverlapping,
      styles,
      hasRigMove,
      zoomLevel,
      active: true,
    }
  }

  getDays = (rigStart, calendarStart, dayWidth, parent = null) => {
    const startObj = typeof rigStart === "string" ? new Date(rigStart) : rigStart

    const isoFrom = parseISO(startObj)
    const isoTo = parseISO(calendarStart)

    const posX = !parent ? differenceInCalendarDays(isoFrom, isoTo) * dayWidth : 0

    return posX
  }

  getStyle = () => {
    const { hasRigMove } = this.state

    const { dayWidth, filter, well, darkMode, PVersion } = this.props

    const style = {}

    if (!well.estimateData[PVersion.value].estimate) {
      style.display = "none"
    }

    style.width = (well.actual_duration || well.estimateData[PVersion.value].estimate) * dayWidth

    if (hasRigMove) {
      style.display = "flex"
    }

    const bookingStatusColor = CU.getWellBookingStatusColor(well.status)

    style.backgroundColor = darkMode ? bookingStatusColor.darkmodeColor : bookingStatusColor.color
    style.borderLeftColor = bookingStatusColor.borderColor

    const assetId = well.well.license ? well.well.license.asset_id : 0
    const licenseId = well.well.license_id
    const name = CU.getFirstNameForWellBookings(well)
    const shortName = well.well.short_name
    const areaId = well.well.license ? well.well.license.area_id : 0

    const wellType = well.well_type

    const hasContractWith = !!well.has_contract_with
    const isHpht = well.well.pressure === "hpht"

    if (CU.shouldWellBeFilteredOut(filter, well.status, assetId, licenseId, name, shortName, hasContractWith, isHpht, areaId, wellType)) {
      style.opacity = "0"
      style.pointerEvents = "none"
    }

    if (well.start_date_is_hard) {
      style.textDecoration = "underline"
    }

    return style
  }

  getIcons = () => {
    const {
      well: {
        has_contract_with: hasContractWith,
        well: { pressure },
      },
    } = this.props

    const icons = []

    if (pressure === "hpht") {
      icons.push(IconContainer(IU.getRigIcon("hpht"), 7, 11))
    }

    if (hasContractWith) {
      icons.unshift(IconContainer(IU.getRigIcon("contract"), 7, 11))
    }
    return icons
  }

  render() {
    const { id, rigId, PVersion } = this.state

    const { well, isSequence, islastSequence } = this.props
    const name = well.well.short_name || CU.getFirstNameForWellBookings(well)
    const style = this.getStyle()
    const wellTitle = (
      <span key={`${PVersion.key}-${rigId}-wellTitle-${id}`} className="wellTitle">
        {name}
      </span>
    )

    const classNames = `Calendar--Entry Well--Booking ${well.status}${style.opacity === "0" ? " filtered-out" : ""}`

    const icons = this.getIcons()

    return (
      <div key="parent" id={`${id}`} style={style} className={`${classNames} ignore-events`}>
        {icons}
        {wellTitle}
        {isSequence && !islastSequence ? Arrow(style, `parent-${id}`) : null}
      </div>
    )
  }
}

WellBooking.propTypes = {
  id: PropTypes.number,
  well: PropTypes.object,
  hasRigMove: PropTypes.bool,
  isSequence: PropTypes.bool,
  islastSequence: PropTypes.bool,
  zoomLevel: PropTypes.number,
  dayWidth: PropTypes.number,
  estimate: PropTypes.number,
  timespan: PropTypes.object,
  rigMove: PropTypes.object,
  filter: PropTypes.object,
  PVersion: PropTypes.object,
  darkMode: PropTypes.bool,
  rigId: PropTypes.number,
  isOverlapping: PropTypes.bool,
  styles: PropTypes.object,
}

export default WellBooking
